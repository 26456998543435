import { template as template_ebbfbb1f57db448099cc30a78b18b1b6 } from "@ember/template-compiler";
const FKText = template_ebbfbb1f57db448099cc30a78b18b1b6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
