import { template as template_310b3871769a4629bc45127032ca8b8a } from "@ember/template-compiler";
const FKLabel = template_310b3871769a4629bc45127032ca8b8a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
