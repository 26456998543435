import { template as template_1f91018ecdc1407a87000915059d9d3a } from "@ember/template-compiler";
const FKControlMenuContainer = template_1f91018ecdc1407a87000915059d9d3a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
