import { template as template_852b5ef5672d4f18b6e59a9e0f305e16 } from "@ember/template-compiler";
const WelcomeHeader = template_852b5ef5672d4f18b6e59a9e0f305e16(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
